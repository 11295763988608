import React from 'react';
import { auth } from './firebase';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaGoogle } from "react-icons/fa";

const LandingPage = () => {
    const [user] = useAuthState(auth);

    const handleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error('Error signing in: ', error.message);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error('Error signing out: ', error.message);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
            <header className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg fixed w-full z-50 shadow-md transition-all duration-300">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center py-4">
                        <div className="flex items-center">
                            <svg className="h-10 w-10 text-indigo-600 transform hover:rotate-12 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                            <span className="ml-3 text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-purple-600 hover:to-indigo-600 transition-all duration-300">APItize</span>
                        </div>
                        <nav className="hidden md:flex space-x-8">
                            {['Features', 'How It Works', 'Pricing'].map((item) => (
                                <a
                                    key={item}
                                    href={`#${item.toLowerCase().replace(/\s+/g, '-')}`}
                                    className="text-gray-600 hover:text-indigo-600 font-medium text-sm uppercase tracking-wider transition duration-300 border-b-2 border-transparent hover:border-indigo-600 py-1"
                                >
                                    {item}
                                </a>
                            ))}
                        </nav>
                        <div>
                            {user ? (
                                <div className="flex items-center space-x-4">
                                    <img className="h-9 w-9 rounded-full ring-2 ring-indigo-600 p-0.5 bg-white" src={user.photoURL} alt="avatar" />
                                    <button
                                        onClick={handleSignOut}
                                        className="px-4 py-2 bg-white text-indigo-600 border border-indigo-600 rounded-full hover:bg-indigo-50 transition duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-sm font-medium"
                                    >
                                        Sign Out
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleSignIn}
                                    className="flex items-center justify-center px-6 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full hover:from-indigo-700 hover:to-purple-700 transition duration-300 transform hover:-translate-y-0.5 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-md group text-sm font-medium"
                                >
                                    <span className="mr-2">Sign In with</span>
                                    <FaGoogle className="w-4 h-4 group-hover:animate-pulse" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </header>

            <main>
                <section className="relative overflow-hidden pt-32 pb-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-28">
                        <div className="text-center">
                            <h1 className="text-6xl font-extrabold mb-6">
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600">Transform CSV to API</span>
                                <br />
                                <span className="text-indigo-900">in Minutes</span>
                            </h1>
                            <p className="mt-4 text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
                                Unleash the power of your data with APItize. No coding required. Build, deploy, and scale your APIs effortlessly.
                            </p>
                            <button
                                onClick={handleSignIn}
                                className="px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full hover:from-indigo-700 hover:to-purple-700 transition duration-300 text-lg font-semibold shadow-lg hover:shadow-xl transform hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Get Started for Free
                            </button>
                        </div>
                    </div>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full -z-10">
                        <div className="absolute inset-0 bg-gradient-to-r from-indigo-100 to-purple-100 filter blur-3xl opacity-70"></div>
                    </div>
                </section>

                <section id="how-it-works" className="bg-white py-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-center text-indigo-900 mb-16">How It Works</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                            {[
                                {
                                    title: "1. Upload CSV",
                                    desc: "Simply drag and drop your CSV file onto our platform.",
                                    icon: <svg className="w-10 h-10 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" /></svg>
                                },
                                {
                                    title: "2. Configure API",
                                    desc: "Customize your API endpoints with our intuitive interface.",
                                    icon: <svg className="w-10 h-10 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" /></svg>
                                },
                                {
                                    title: "3. Use Your API",
                                    desc: "Start using your new API instantly in your applications.",
                                    icon: <svg className="w-10 h-10 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                }
                            ].map((step, index) => (
                                <div key={index} className="text-center bg-white rounded-lg shadow-lg p-8 transform hover:-translate-y-1 transition duration-300">
                                    <div className="bg-indigo-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
                                        {step.icon}
                                    </div>
                                    <h3 className="text-2xl font-semibold text-indigo-900 mb-4">{step.title}</h3>
                                    <p className="text-gray-600">{step.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section id="features" className="bg-gradient-to-b from-indigo-900 to-purple-900 text-white py-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-center mb-16">Powerful Features</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                            {[
                                { title: "Custom Endpoints", desc: "Create tailored API endpoints for your specific needs." },
                                { title: "Data Validation", desc: "Ensure data integrity with built-in validation rules." },
                                { title: "Secure Authentication", desc: "Protect your API with industry-standard authentication." },
                                { title: "Real-time Updates", desc: "Keep your API in sync with your latest data changes." },
                                { title: "Version Control", desc: "Manage different versions of your API effortlessly." },
                                { title: "Analytics Dashboard", desc: "Monitor API usage and performance with detailed insights." },
                            ].map((feature, index) => (
                                <div key={index} className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-8 shadow-lg transform hover:-translate-y-1 transition duration-300">
                                    <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                                    <p className="text-indigo-200">{feature.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section id="pricing" className="bg-white py-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-center text-indigo-900 mb-16">Pricing Plans</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {[
                                { name: "Free", price: "$0", features: ["1 API", "1,000 requests/month", "Basic support"] },
                                { name: "Pro", price: "$29", features: ["Unlimited APIs", "100,000 requests/month", "Priority support", "Custom domains"] },
                                { name: "Enterprise", price: "Custom", features: ["Unlimited everything", "24/7 support", "Dedicated account manager", "On-premise deployment"] },
                            ].map((plan, index) => (
                                <div key={index} className="bg-white border-2 border-indigo-100 rounded-lg shadow-lg overflow-hidden transform hover:-translate-y-1 transition duration-300">
                                    <div className="p-8">
                                        <h3 className="text-2xl font-bold text-indigo-900 mb-2">{plan.name}</h3>
                                        <p className="text-4xl font-extrabold text-indigo-600 mb-6">{plan.price}</p>
                                        <ul className="mb-8">
                                            {plan.features.map((feature, i) => (
                                                <li key={i} className="flex items-center mb-2">
                                                    <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="p-6 bg-indigo-50">
                                        <button
                                            onClick={handleSignIn}
                                            className="w-full px-4 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="bg-gradient-to-r from-indigo-600 to-purple-600 py-20">
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                        <h2 className="text-4xl font-bold text-white mb-6">Ready to Transform Your Data?</h2>
                        <p className="text-xl text-indigo-100 mb-8">Join thousands of businesses already using APItize to power their applications.</p>
                        <button
                            onClick={handleSignIn}
                            className="px-8 py-4 bg-white text-indigo-600 rounded-full hover:bg-indigo-100 transition duration-300 text-lg font-semibold shadow-lg hover:shadow-xl transform hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                        >
                            Start Your Free Trial
                        </button>
                    </div>
                </section>
            </main>

            <footer className="bg-indigo-900 text-white py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Product</h3>
                            <ul className="space-y-2">
                                <li><a href="#" className="hover:text-indigo-300 transition duration-300">Features</a></li>
                                <li><a href="#" className="hover:text-indigo-300 transition duration-300">Pricing</a></li>
                                <li><a href="#" className="hover:text-indigo-300 transition duration-300">Use Cases</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Company</h3>
                            <ul className="space-y-2">
                                <li><a href="#" className="hover:text-indigo-300 transition duration-300">About</a></li>
                                <li><a href="#" className="hover:text-indigo-300 transition duration-300">Blog</a></li>
                                <li><a href="#" className="hover:text-indigo-300">Careers</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Resources</h3>
                            <ul className="space-y-2">
                                <li><a href="#" className="hover:text-indigo-300">Documentation</a></li>
                                <li><a href="#" className="hover:text-indigo-300">API Reference</a></li>
                                <li><a href="#" className="hover:text-indigo-300">Support</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Legal</h3>
                            <ul className="space-y-2">
                                <li><a href="#" className="hover:text-indigo-300">Privacy Policy</a></li>
                                <li><a href="#" className="hover:text-indigo-300">Terms of Service</a></li>
                                <li><a href="#" className="hover:text-indigo-300">Cookie Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-12 text-center">
                        <p>&copy; 2024 APItize. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
