import React from 'react';

export default function DataTable({ data, rowCount }) {
    if (!data || data.length === 0) return null;
    const headers = Object.keys(data[0]);
    const rows = data.slice(0, rowCount);
    return (
        <div className="overflow-x-auto bg-white rounded-lg shadow">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        {headers.map((header) => (
                            <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {rows.map((row, index) => (
                        <tr key={index}>
                            {headers.map((header) => (
                                <td key={header} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row[header]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
