import React from 'react';
import { FiPlus, FiX } from 'react-icons/fi';

export default function FilterSelection({
    columns,
    fieldTypes,
    filters,
    currentFilter,
    setCurrentFilter,
    handleFilterChange,
    addFilter,
    setFilters,
    filterLogic,
    setFilterLogic,
}) {
    return (
        <div className="mb-8 bg-white rounded-lg shadow-md p-4 sm:p-6">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-800 border-b pb-3">4. Add filters</h2>

            <div className="space-y-4">
                <div className="flex flex-wrap gap-4">
                    <select
                        onChange={(e) => setCurrentFilter({ ...currentFilter, column: e.target.value })}
                        className="flex-grow sm:flex-grow-0 p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                        value={currentFilter.column}
                    >
                        <option value="">Select Column</option>
                        {columns.map((column) => (
                            <option key={column} value={column}>{column}</option>
                        ))}
                    </select>

                    {currentFilter.column && (
                        <>
                            <select
                                onChange={(e) => setCurrentFilter({ ...currentFilter, operator: e.target.value })}
                                className="flex-grow sm:flex-grow-0 p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                                value={currentFilter.operator}
                            >
                                <option value="">Select Operator</option>
                                {fieldTypes[currentFilter.column] && fieldTypes[currentFilter.column].match(/int|float/) && (
                                    <>
                                        <option value="$gt">&gt;</option>
                                        <option value="$lt">&lt;</option>
                                    </>
                                )}
                                <option value="$eq">=</option>
                                <option value="$ne">!=</option>
                                <option value="$eqField">= (Field)</option>
                            </select>

                            <input
                                type="text"
                                onChange={(e) => setCurrentFilter({ ...currentFilter, value: e.target.value })}
                                value={currentFilter.value}
                                placeholder="Value"
                                className="flex-grow sm:flex-grow-0 p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                            />
                        </>
                    )}

                    <button
                        onClick={addFilter}
                        disabled={!currentFilter.column || !currentFilter.operator || !currentFilter.value}
                        className="flex-grow sm:flex-grow-0 p-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <FiPlus className="inline-block mr-2" /> Add Filter
                    </button>
                </div>

                <div className="mt-4">
                    <h3 className="text-lg font-medium text-gray-700 mb-2">Active Filters</h3>
                    <div className="flex flex-wrap gap-2">
                        {filters.map((filter, index) => (
                            <span key={index} className="inline-flex items-center bg-indigo-100 rounded-full px-3 py-1 text-sm font-medium text-indigo-800">
                                {filter.column} {filter.operator} {filter.value}
                                <button
                                    onClick={() => setFilters(filters.filter((_, i) => i !== index))}
                                    className="ml-2 text-indigo-600 hover:text-indigo-800 focus:outline-none"
                                >
                                    <FiX className="h-4 w-4" />
                                </button>
                            </span>
                        ))}
                    </div>
                </div>

                <div className="mt-4">
                    <h3 className="text-lg font-medium text-gray-700 mb-2">Filter Logic</h3>
                    <div className="flex space-x-4">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                className="form-radio text-indigo-600"
                                name="filterLogic"
                                value="AND"
                                checked={filterLogic === "AND"}
                                onChange={(e) => setFilterLogic(e.target.value)}
                            />
                            <span className="ml-2">AND</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                className="form-radio text-indigo-600"
                                name="filterLogic"
                                value="OR"
                                checked={filterLogic === "OR"}
                                onChange={(e) => setFilterLogic(e.target.value)}
                            />
                            <span className="ml-2">OR</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}