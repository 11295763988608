import React from 'react';

export default function EndpointSelection({
    suggestions,
    selectedEndpoint,
    customEndpoint,
    setSelectedEndpoint,
    setCustomEndpoint,
    handleGetSuggestions,
}) {
    return (
        <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. Select or Enter Endpoint</h2>
            <div className="flex space-x-2 mb-4">
                <select
                    value={selectedEndpoint}
                    onChange={(e) => setSelectedEndpoint(e.target.value)}
                    className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500"
                >
                    <option value="">Select an endpoint</option>
                    {suggestions.map((suggestion) => (
                        <option key={suggestion} value={suggestion}>
                            {suggestion}
                        </option>
                    ))}
                </select>
                <button
                    onClick={handleGetSuggestions}
                    className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition duration-300"
                >
                    Get Suggestions
                </button>
            </div>
            <input
                type="text"
                value={customEndpoint}
                onChange={(e) => setCustomEndpoint(e.target.value)}
                placeholder="/custom/endpoint"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500"
            />
        </div>
    );
}
