import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUploadCloud } from 'react-icons/fi';

export default function FileUpload({ handleFileUpload, columns, data }) {
    const [isDragActive, setIsDragActive] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            handleFileUpload({ target: { files: acceptedFiles } });
        }
    }, [handleFileUpload]);

    const { getRootProps, getInputProps, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.csv']
        },
        multiple: false
    });

    const handleStartOver = () => {
        window.location.reload();
    };

    return (
        <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Upload CSV</h2>
            {columns.length === 0 && (
                <div
                    {...getRootProps()}
                    className={`p-8 border-2 border-dashed rounded-lg text-center cursor-pointer transition duration-300 ease-in-out ${isDragActive ? 'border-indigo-500 bg-indigo-50' : 'border-gray-300 hover:border-indigo-400'
                        } ${isDragReject ? 'border-red-500 bg-red-50' : ''}`}
                    onDragEnter={() => setIsDragActive(true)}
                    onDragLeave={() => setIsDragActive(false)}
                >
                    <input {...getInputProps()} />
                    <FiUploadCloud className="mx-auto h-12 w-12 text-gray-400" />
                    <p className="mt-2 text-sm font-medium text-gray-900">
                        Drag and drop your CSV file here, or click to select
                    </p>
                    <p className="mt-1 text-xs text-gray-500">CSV files only, up to 10MB</p>
                </div>
            )}
            {columns.length > 0 && (
                <div className="mt-4 p-4 bg-green-50 rounded-lg">
                    <p className="text-sm font-medium text-green-800">File uploaded successfully!</p>
                    <p className="mt-1 text-xs text-green-700">Columns loaded: {columns.join(', ')}</p>
                    {data && (
                        <table className="min-w-full mt-4 bg-white shadow-md rounded-lg">
                            <thead>
                                <tr>
                                    {columns.map((column, index) => (
                                        <th key={index} className="px-4 py-2 text-left text-sm font-semibold text-gray-600 border-b border-gray-200">
                                            {column}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.slice(0, 5).map((row, rowIndex) => (
                                    <tr key={rowIndex} className="bg-white even:bg-gray-50">
                                        {columns.map((column, colIndex) => (
                                            <td key={colIndex} className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
                                                {row[column]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    <button
                        onClick={handleStartOver}
                        className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                    >
                        Start Over
                    </button>
                </div>
            )}
        </div>
    );
}
