import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from './firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import FileUpload from './components/FileUpload';
import EndpointSelection from './components/EndpointSelection';
import FieldSelection from './components/FieldSelection';
import FilterSelection from './components/FilterSelection';
import DataTable from './components/DataTable';
import FetchEndpointData from './components/FetchEndpointData';
import CreatedEndpoints from './components/CreatedEndpoints';
import { TbClipboardCopy, TbClipboardCheck } from "react-icons/tb";
import { FiPlus, FiLogOut, FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { TbApi, TbDatabaseSearch } from "react-icons/tb";

const apiUrl = 'https://apitize.noobie.cloud/api';

const db = getFirestore();

function MainApp() {
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState('');
    const [columns, setColumns] = useState([]);
    const [fieldTypes, setFieldTypes] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [selectedEndpoint, setSelectedEndpoint] = useState('');
    const [customEndpoint, setCustomEndpoint] = useState('');
    const [selectedFields, setSelectedFields] = useState([]);
    const [endpointData, setEndpointData] = useState(null);
    const [error, setError] = useState(null);
    const [createdEndpoints, setCreatedEndpoints] = useState([]);
    const [filters, setFilters] = useState([]);
    const [filterLogic, setFilterLogic] = useState('AND');
    const [previewRowCount, setPreviewRowCount] = useState(5);
    const [currentFilter, setCurrentFilter] = useState({ column: '', operator: '', value: '' });
    const [step, setStep] = useState(1);
    const [user, setUser] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [tokenCopied, setTokenCopied] = useState(false);
    const [csvCount, setCsvCount] = useState(0);
    const [activeMenuItem, setActiveMenuItem] = useState('list');
    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                fetchCreatedEndpoints(currentUser);
                fetchCsvCount(currentUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchCreatedEndpoints = async (currentUser) => {
        try {
            const token = await currentUser.getIdToken();
            const response = await axios.get(`${apiUrl}/list-endpoints`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCreatedEndpoints(response.data);
        } catch (error) {
            setError('Failed to fetch created endpoints');
        }
    };

    const renderContent = () => {
        switch (activeMenuItem) {
            case 'list':
                return (
                    <CreatedEndpoints
                        createdEndpoints={createdEndpoints}
                        apiUrl={apiUrl}
                        userId={user?.uid}
                    />
                );
            case 'create':
                return (
                    <>
                        <FileUpload handleFileUpload={handleFileUpload} columns={columns} data={data} />
                        {step >= 2 && (
                            <>
                                <EndpointSelection
                                    suggestions={suggestions}
                                    selectedEndpoint={selectedEndpoint}
                                    customEndpoint={customEndpoint}
                                    setSelectedEndpoint={setSelectedEndpoint}
                                    setCustomEndpoint={setCustomEndpoint}
                                    handleGetSuggestions={handleGetSuggestions}
                                />
                                <FieldSelection columns={columns} selectedFields={selectedFields} setSelectedFields={setSelectedFields} />
                                <FilterSelection
                                    columns={columns}
                                    fieldTypes={fieldTypes}
                                    filters={filters}
                                    currentFilter={currentFilter}
                                    setCurrentFilter={setCurrentFilter}
                                    handleFilterChange={handleFilterChange}
                                    addFilter={addFilter}
                                    setFilters={setFilters}
                                    filterLogic={filterLogic}
                                    setFilterLogic={setFilterLogic}
                                />
                                <div className="flex justify-center space-x-4 mb-8">
                                    <button
                                        onClick={handleCreateEndpoint}
                                        className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition duration-300 text-lg font-semibold"
                                    >
                                        Create Endpoint
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                );
            case 'fetch':
                return (
                    <>
                        <FetchEndpointData
                            createdEndpoints={createdEndpoints}
                            selectedEndpoint={selectedEndpoint}
                            setSelectedEndpoint={setSelectedEndpoint}
                            fetchEndpointData={fetchEndpointData}
                            user={user}
                        />
                        {endpointData && (
                            <>
                                <div className="mb-8">
                                    <h2 className="text-2xl font-semibold mb-4 text-gray-800">Endpoint Data Preview</h2>
                                    <DataTable data={endpointData} rowCount={previewRowCount} />
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Preview Row Count:
                                            <input
                                                type="number"
                                                value={previewRowCount}
                                                onChange={(e) => setPreviewRowCount(parseInt(e.target.value))}
                                                className="ml-2 p-1 text-sm text-gray-900 border border-gray-300 rounded"
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-8">
                                    <h2 className="text-2xl font-semibold mb-4 text-gray-800">API Usage</h2>
                                    <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
                                        <code>
                                            {`GET ${apiUrl}/${customEndpoint || selectedEndpoint}
Response will be a JSON array of objects with the following structure:
${JSON.stringify(endpointData[0], null, 2)}`}
                                        </code>
                                    </pre>
                                </div>
                            </>
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    const fetchCsvCount = async (currentUser) => {
        if (!currentUser || !currentUser.uid) {
            setError('Failed to fetch CSV count: invalid user');
            return;
        }

        try {
            console.log('Fetching CSV count for user ID:', currentUser.uid);
            const q = query(
                collection(db, 'csv_data'),
                where('user_id', '==', currentUser.uid)
            );
            const querySnapshot = await getDocs(q);
            setCsvCount(querySnapshot.size);
        } catch (error) {
            console.error('Error fetching CSV count:', error);
            setError('Failed to fetch CSV count: ' + error.message);
        }
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setFile(file);
        setFilename(file.name);
        const formData = new FormData();
        formData.append('file', file);
        try {
            const token = await user.getIdToken();
            const response = await axios.post(`${apiUrl}/upload-csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });
            setColumns(response.data.columns);
            setFieldTypes(response.data.types);
            setData(response.data.rows); // Assuming your API returns rows as well
            setError(null);
            setStep(2);
        } catch (error) {
            setError('Error uploading file: ' + error.message);
        }
    };


    const handleGetSuggestions = async () => {
        try {
            const token = await user.getIdToken();
            const response = await axios.get(`${apiUrl}/suggest-endpoints`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSuggestions(response.data);
            setError(null);
        } catch (error) {
            setError('Error getting suggestions: ' + error.message);
        }
    };

    const handleCreateEndpoint = async () => {
        let endpoint = customEndpoint || selectedEndpoint;
        endpoint = endpoint.replace(/^\//, '');
        if (!endpoint || selectedFields.length === 0) {
            setError('Please select an endpoint and at least one field');
            return;
        }
        try {
            const formattedFilters = filters.reduce((acc, filter) => {
                if (filter.operator === '$eqField') {
                    acc[filter.column] = { [filter.operator]: filter.value };
                } else if (['$gt', '$lt', '$eq', '$ne'].includes(filter.operator)) {
                    acc[filter.column] = { [filter.operator]: filter.value };
                } else {
                    acc[filter.column] = filter.value;
                }
                return acc;
            }, {});

            const token = await user.getIdToken();
            const response = await axios.post(`${apiUrl}/create-endpoint?filename=${filename}&filter_logic=${filterLogic}`, {
                endpoint: endpoint,
                fields: selectedFields,
                filters: formattedFilters,
                filter_logic: filterLogic
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setError(null);
            alert('Endpoint created successfully');
            fetchCreatedEndpoints(user);
            setStep(4);
        } catch (error) {
            setError('Error creating endpoint: ' + error.message);
        }
    };

    const handleFilterChange = (column, operator, value) => {
        setFilters(filters => [
            ...filters.filter(filter => filter.column !== column),
            { column, operator, value }
        ]);
        setCurrentFilter({ column, operator, value });
    };

    const UserDropdown = ({ user, expanded, onSignOut, onCopyToken, tokenCopied }) => {
        const [dropdownOpen, setDropdownOpen] = useState(false);

        return (
            <div className="relative">
                <button
                    className="flex items-center space-x-2 w-full"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                    <img
                        className="h-8 w-8 rounded-full object-cover border-2 border-white"
                        src={user.photoURL}
                        alt="User avatar"
                    />
                    {expanded && <span className="ml-2 text-sm font-medium text-white">{user.displayName}</span>}
                </button>
                {dropdownOpen && (
                    <div className={`absolute ${expanded ? 'right-0 bottom-full mb-2' : 'left-full bottom-0 ml-2'} w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}>
                        <div className="py-1">
                            <DropdownButton icon={TbClipboardCopy} label="Copy Token" onClick={onCopyToken} />
                            {tokenCopied && <DropdownItem icon={TbClipboardCheck} label="Token Copied!" color="text-green-500" />}
                            <DropdownButton icon={FiLogOut} label="Sign Out" onClick={onSignOut} color="text-red-500" hoverColor="hover:bg-red-100" />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    // Update the SidebarButton component for better appearance
    const SidebarButton = ({ icon: Icon, label, active, expanded, onClick }) => (
        <button
            onClick={onClick}
            className={`flex items-center w-full p-3 rounded-lg transition duration-300 ${active
                ? 'bg-white text-indigo-800 shadow-md'
                : 'text-white hover:bg-indigo-100 hover:text-indigo-800 hover:shadow-md'
                }`}
        >
            <Icon size={20} className={`${active ? 'stroke-indigo-800' : 'stroke-white'}`} />
            {expanded && (
                <span className={`ml-3 text-sm font-medium ${active ? 'text-indigo-800' : 'text-white group-hover:text-indigo-800'
                    }`}>
                    {label}
                </span>
            )}
        </button>
    );

    // Update the DropdownButton component for better appearance
    const DropdownButton = ({ icon: Icon, label, onClick, color = "text-indigo-500", hoverColor = "hover:bg-indigo-100" }) => (
        <button
            onClick={onClick}
            className={`flex items-center w-full px-4 py-2 text-sm text-gray-700 ${hoverColor} transition duration-150 ease-in-out`}
        >
            <Icon className={`h-5 w-5 mr-3 ${color}`} />
            <span>{label}</span>
        </button>
    );

    const DropdownItem = ({ icon: Icon, label, color = "text-gray-700" }) => (
        <div className={`flex items-center px-4 py-2 text-sm ${color}`}>
            <Icon className="h-5 w-5 mr-3" />
            <span>{label}</span>
        </div>
    );

    const addFilter = () => {
        if (currentFilter.column && currentFilter.operator && currentFilter.value) {
            handleFilterChange(currentFilter.column, currentFilter.operator, currentFilter.value);
            setCurrentFilter({ column: '', operator: '', value: '' });
        }
    };

    const fetchEndpointData = async () => {
        const endpoint = customEndpoint || selectedEndpoint;
        if (!endpoint) {
            setError('Please select or enter an endpoint');
            return;
        }
        try {
            const token = await user.getIdToken();
            const formattedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
            const response = await axios.get(`${apiUrl}${formattedEndpoint}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setEndpointData(response.data);
            setError(null);
        } catch (error) {
            setError('Error fetching endpoint data: ' + error.message);
            setEndpointData(null);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            setError('Error signing out: ' + error.message);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const copyFirebaseToken = async () => {
        try {
            const token = await user.getIdToken();
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(token);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = token;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);
            }
            setTokenCopied(true);
            setTimeout(() => setTokenCopied(false), 2000); // Reset the copied state after 2 seconds
        } catch (error) {
            setError('Error copying Firebase token: ' + error.message);
        }
    };

    const toggleSidebar = () => {
        setSidebarExpanded(!sidebarExpanded);
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <div className={`fixed inset-y-0 left-0 z-30 flex flex-col bg-indigo-800 text-white transition-all duration-300 ${sidebarExpanded ? 'w-64' : 'w-16'} shadow-lg`}>
                <div className="flex flex-col h-full">
                    <div className="flex items-center justify-between p-4">
                        <div className="flex items-center">
                            <svg className="h-8 w-8 text-indigo-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                            {sidebarExpanded && <span className="ml-2 text-xl font-bold text-white">APItize</span>}
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col space-y-4 mt-8 px-2">
                        <SidebarButton icon={TbApi} label="Endpoints" active={activeMenuItem === 'list'} expanded={sidebarExpanded} onClick={() => setActiveMenuItem('list')} />
                        <SidebarButton icon={FiPlus} label="Create" active={activeMenuItem === 'create'} expanded={sidebarExpanded} onClick={() => setActiveMenuItem('create')} />
                        <SidebarButton icon={TbDatabaseSearch} label="Fetch" active={activeMenuItem === 'fetch'} expanded={sidebarExpanded} onClick={() => setActiveMenuItem('fetch')} />
                    </div>
                    <div className="mt-auto">
                        <button
                            onClick={toggleSidebar}
                            className={`w-full p-2 bg-indigo-600 hover:bg-indigo-500 transition duration-300 flex items-center justify-center text-white`}
                        >
                            {sidebarExpanded ? <FiChevronLeft size={24} /> : <FiChevronRight size={24} />}
                        </button>
                        {user && (
                            <div className="p-4 border-t border-indigo-700">
                                <UserDropdown user={user} expanded={sidebarExpanded} onSignOut={handleSignOut} onCopyToken={copyFirebaseToken} tokenCopied={tokenCopied} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Main content */}
            <div className={`flex-1 transition-all duration-300 ${sidebarExpanded ? 'ml-64' : 'ml-16'}`}>
                <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="p-8">
                            {renderContent()}
                            {error && (
                                <div className="mt-8 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                                    <p>{error}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default MainApp;
