import React, { useState, useEffect } from 'react';
import { FiChevronDown, FiChevronUp, FiCopy, FiChevronLeft, FiChevronRight, FiDatabase } from 'react-icons/fi';
import { AiOutlineApi } from "react-icons/ai";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import toast, { Toaster } from 'react-hot-toast';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Update this import to match your Firebase setup

export default function CreatedEndpoints({ createdEndpoints, apiUrl, totalRequests, userId }) {
    const [openEndpoint, setOpenEndpoint] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [csvTransformedCount, setCsvTransformedCount] = useState(null);
    const itemsPerPage = 5;

    useEffect(() => {
        const fetchCsvTransformedCount = async () => {
            try {
                const q = query(collection(db, 'csv_data'), where('user_id', '==', userId));
                const querySnapshot = await getDocs(q);
                setCsvTransformedCount(querySnapshot.size);
            } catch (error) {
                console.error('Error fetching CSV transformed count:', error);
                setCsvTransformedCount(0); // Set to 0 to indicate failure
            }
        };

        fetchCsvTransformedCount();
    }, [userId]);

    useEffect(() => {
    }, [csvTransformedCount]);

    const totalPages = Math.ceil(createdEndpoints.length / itemsPerPage);
    const paginatedEndpoints = createdEndpoints.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const toggleEndpoint = (endpoint) => {
        setOpenEndpoint(openEndpoint === endpoint ? null : endpoint);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success('Code copied to clipboard!', {
                icon: '📋',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            toast.error('Failed to copy code');
        });
    };

    const renderCodeExamples = (endpoint) => {
        const fullUrl = `${apiUrl}/${endpoint}`;

        const examples = {
            node: `
const axios = require('axios');

axios.get('${fullUrl}', {
  headers: {
    'Authorization': 'Bearer {{Your APItize token}}'
  }
})
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error('Error:', error);
  });
            `,
            python: `
import requests

headers = {
    'Authorization': 'Bearer {{Your APItize token}}'
}

response = requests.get('${fullUrl}', headers=headers)
if response.status_code == 200:
    print(response.json())
else:
    print('Error:', response.status_code)
            `,
            curl: `
curl -X GET "${fullUrl}" \\
     -H "Authorization: Bearer {{Your APItize token}}"
            `
        };

        return (
            <CodeTabs examples={examples} onCopy={copyToClipboard} />
        );
    };

    return (
        <div className="bg-gray-50 p-8 rounded-xl shadow-lg">
            <Toaster position="top-right" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <StatCard icon={<AiOutlineApi className="w-8 h-8" />} title="Endpoints" value={createdEndpoints?.length || 0} />
                <StatCard icon={<FiDatabase className="w-8 h-8" />} title="CSV Transformed" value={csvTransformedCount !== null ? csvTransformedCount : 'Loading...'} />
            </div>
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="px-6 py-4 border-b border-gray-200">
                    <h2 className="text-xl font-semibold text-gray-800">Created Endpoints</h2>
                </div>
                {paginatedEndpoints.length > 0 ? (
                    paginatedEndpoints.map((endpoint, index) => (
                        <div key={endpoint} className={`border-b border-gray-200 ${index === paginatedEndpoints.length - 1 ? 'border-b-0' : ''}`}>
                            <button
                                className="w-full text-left px-6 py-4 hover:bg-gray-50 transition-colors duration-200 flex justify-between items-center"
                                onClick={() => toggleEndpoint(endpoint)}
                            >
                                <span className="text-indigo-600 font-medium">{endpoint}</span>
                                {openEndpoint === endpoint ? (
                                    <FiChevronUp className="text-gray-500" />
                                ) : (
                                    <FiChevronDown className="text-gray-500" />
                                )}
                            </button>
                            {openEndpoint === endpoint && (
                                <div className="px-6 py-4 bg-gray-50">
                                    <div className="mb-4">
                                        <a
                                            href={`${apiUrl}/${endpoint}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:underline"
                                        >
                                            {`${apiUrl}/${endpoint}`}
                                        </a>
                                    </div>
                                    {renderCodeExamples(endpoint)}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <div className="px-6 py-4 text-gray-500">No endpoints available.</div>
                )}
            </div>
            {totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                />
            )}
        </div>
    );
}

function CodeTabs({ examples, onCopy }) {
    const [activeTab, setActiveTab] = useState('node');

    return (
        <div className="mt-4">
            <div className="flex border-b">
                {Object.keys(examples).map((lang) => (
                    <button
                        key={lang}
                        className={`px-4 py-2 font-medium ${activeTab === lang ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
                        onClick={() => setActiveTab(lang)}
                    >
                        {lang.charAt(0).toUpperCase() + lang.slice(1)}
                    </button>
                ))}
            </div>
            <div className="mt-4">
                <CodeBlock
                    language={activeTab}
                    code={examples[activeTab]}
                    onCopy={onCopy}
                />
            </div>
        </div>
    );
}

function CodeBlock({ language, code, onCopy }) {
    return (
        <div className="relative bg-gray-800 rounded-lg overflow-hidden">
            <div className="flex justify-between items-center px-4 py-2 bg-gray-700">
                <span className="text-sm font-medium text-gray-200">{language}</span>
                <button
                    onClick={() => onCopy(code)}
                    className="text-gray-400 hover:text-white transition-colors duration-200"
                    title="Copy to clipboard"
                >
                    <FiCopy />
                </button>
            </div>
            <SyntaxHighlighter language={language} style={atomDark} customStyle={{
                margin: 0,
                padding: '1rem',
                fontSize: '0.875rem',
            }}>
                {code.trim()}
            </SyntaxHighlighter>
        </div>
    );
}

function StatCard({ icon, title, value }) {
    return (
        <div className="bg-white p-6 rounded-xl shadow-sm flex items-center space-x-4">
            <div className="p-3 bg-indigo-100 rounded-full text-indigo-600">{icon}</div>
            <div>
                <h3 className="text-gray-500 text-sm font-medium">{title}</h3>
                <p className="text-gray-800 font-semibold text-2xl">
                    {value !== undefined ? value.toLocaleString() : 'N/A'}
                </p>
            </div>
        </div>
    );
}

function Pagination({ currentPage, totalPages, onPageChange }) {
    return (
        <div className="flex justify-between items-center mt-6">
            <button
                onClick={() => onPageChange(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
                <FiChevronLeft className="mr-2" />
                Previous
            </button>
            <span className="text-sm text-gray-700">
                Page {currentPage} of {totalPages}
            </span>
            <button
                onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
                disabled={currentPage === totalPages}
                className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
                Next
                <FiChevronRight className="ml-2" />
            </button>
        </div>
    );
}

