// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import LandingPage from './LandingPage';
import MainApp from './MainApp';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a better loading indicator
  }

  return (
    <Router>
      <Switch>
        <Route path="/app">
          {user ? <MainApp /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/">
          {user ? <Redirect to="/app" /> : <LandingPage />}
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default App;
