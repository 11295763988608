import React from 'react';

export default function FieldSelection({ columns, selectedFields, setSelectedFields }) {
    return (
        <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Select fields to include</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {columns.map((column) => (
                    <label key={column} className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={selectedFields.includes(column)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedFields([...selectedFields, column]);
                                } else {
                                    setSelectedFields(selectedFields.filter((field) => field !== column));
                                }
                            }}
                            className="form-checkbox h-5 w-5 text-indigo-600 rounded"
                        />
                        <span className="ml-2 text-sm text-gray-700">{column}</span>
                    </label>
                ))}
            </div>
        </div>
    );
}
