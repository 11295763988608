// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBXU1AEKWAS29oL5K-vNYx-7lko82A9Shs",
    authDomain: "apitize-app.firebaseapp.com",
    projectId: "apitize-app",
    storageBucket: "apitize-app.appspot.com",
    messagingSenderId: "871098145132",
    appId: "1:871098145132:web:52a222644f5e0eeaa9bb49",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const signInAndGetIdToken = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const idToken = await user.getIdToken();
        return idToken;
    } catch (error) {
        console.error("Error signing in:", error);
        throw error;
    }
};

export { auth, db, storage, signInAndGetIdToken };
