import React from 'react';

export default function FetchEndpointData({ createdEndpoints, selectedEndpoint, setSelectedEndpoint, fetchEndpointData, user }) {
  const handleFetch = async () => {
    const token = await user.getIdToken();
    await fetchEndpointData(token);
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Fetch Endpoint Data</h2>
      <div className="flex space-x-4">
        <select
          value={selectedEndpoint}
          onChange={(e) => setSelectedEndpoint(e.target.value)}
          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <option value="">Select an endpoint</option>
          {createdEndpoints.map((endpoint) => (
            <option key={endpoint} value={endpoint}>
              {endpoint}
            </option>
          ))}
        </select>
        <button onClick={handleFetch} className="px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition duration-300">
          Fetch
        </button>
      </div>
    </div>
  );
}
